import logger from '~/services/logger';
import getCellsVars from './get-cells-vars.ts';
import getComponentsVars from './get-components-vars.ts';
import getGroupVars from './get-group-vars.ts';
import getNavigationVars from './get-navigation-vars.ts';
import getNavBarItemVars from './navigation/get-nav-bar-item-vars.ts';
import getScreenVars from './get-screen-vars.ts';
import getSearchVars from './get-search-vars.ts';
import getFooterVars from './get_footer_vars.ts';
import getUserAccountVars from './get_user_account_vars.ts';
import getOAuthVars from './get-oauth-vars.ts';
import getThemeVars from './get-theme-vars.ts';
import getCellsVarsV2 from './get-cells-vars-v2.ts';

export {
  getThemeVars,
  getComponentsVars,
  getGroupVars,
  getCellsVars,
  getCellsVarsV2,
  getNavigationVars,
  getNavBarItemVars,
  getSearchVars,
  getScreenVars,
  getUserAccountVars,
  getFooterVars,
  getOAuthVars,
};

/**
 * Extracts metadata from the input data object if the screen.id property exists.
 * @param data - The input data object from a remix loader.
 * @param routePath - The internal css-vars route path to be used.
 * @returns An array that contains the metadata and a link object if they exists.
 */
export function cssVarsMetaFunctionData({
  data,
  routePath,
}: {
  data: any;
  routePath: string;
}) {
  try {
    const links = data?.screen?.id
      ? [
          {
            tagName: 'link',
            rel: 'stylesheet',
            href: `/${routePath}?screenId=${data?.screen?.id}&${data.hash}${
              data.debugLayoutId ? `&debugLayoutId=${data.debugLayoutId}` : ''
            }`,
            as: 'style',
          },
        ]
      : [];

    return links;
  } catch (error: any) {
    logger.info(`####### cssVarsMetaFunctionData: ${error.message}`);
    return [];
  }
}
